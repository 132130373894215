<template>
  <div class="container">
    <div class="row justify-content-center align-items-center" style="height: 100vh;">
      <div class="col-md-4">
        <div class="processing-card shadow-sm">
          <div class="text-center">
            <h2>Processing Your Files</h2>
            <p class="text-muted mt-3">
              We are currently translating your files. This may take a few minutes.
            </p>

            <p class="text-muted">
              You will receive an email at <strong>{{ userEmail }}</strong> once all files have been translated and are ready for download.
            </p>
            <p>If you have not received the email, please check your spam folder. If it is not there, feel free to contact us at <strong>translateanyformat@gmail.com</strong> and provide your translation reference: <strong>{{translatedObjId}}</strong>
              <button @click="copyToClipboard" class="btn btn-secondary btn-sm ms-2" ref="copyButton" :class="{'is-copied': isCopied}">Copy</button>
            </p>

            <p class="text-muted mt-3">
              <strong>Download link will appear below after processing is finished!</strong>
            </p>
            <div v-if="!isDownloadReady" class="loader mt-4"></div>
            <button v-else @click="downloadFile" class="btn btn-primary btn-sm ms-2">Download</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  computed: {
    userEmail() {
      const params = new URLSearchParams(window.location.search);
      return params.get('user_email')?.toLowerCase();
    },
    translatedObjId() {
      const params = new URLSearchParams(window.location.search);
      return params.get('pay_obj')?.toLowerCase();
    }
  },
  data() {
    return {
      isCopied: false, // Track if the text has been copied
      isDownloadReady: false,
      isDownloadReadyInterval: null,
      downloadId: null
    }
  },
  mounted() {
    this.isDownloadReadyInterval = setInterval(this.checkIsDownloadReady, 3000)
    this.$gtm.trackEvent({
      event: "view-paid-view"
    })
  },
  methods: {
    checkIsDownloadReady() {
      axios.get(`https://ai.data.arr-services.nl/api/v1/translated_objs/${this.translatedObjId}/_is_download_ready`).then(response => {
        let data = response.data
        if (data.status === "ready") {
          clearInterval(this.isDownloadReadyInterval)
          this.isDownloadReady = true
          this.downloadId = data.download_id
        }
      }).catch(error => {
        console.log(error)
      })
    },
    downloadFile() {
      const url = `https://file-server.arr-services.nl/api/v1/file_infos/download_global/${this.downloadId}`;
      const filename = 'translated.zip';

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    copyToClipboard() {
      // Create a temporary input to select the content
      const input = document.createElement('input');
      input.value = this.translatedObjId;
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);

      // Show the copied tag
      this.isCopied = true;

      // Hide the copied tag after 2 seconds with opacity fade out
      setTimeout(() => {
        this.isCopied = false;
      }, 1000);
    }
  }
};
</script>

<style scoped>
.container {
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.processing-card {
  padding: 30px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  min-width: 480px;
  max-width: 680px;
}

h2 {
  color: #007bff;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin: 20px auto 0;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

body::before {
  content: "";
  background-image: url('../assets/background.jpg');
  background-size: cover;
  background-position: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0.8;
}

button {
  cursor: pointer;
  position: relative;
  z-index: 1;
  background-color: #0b91d2;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

button.is-copied {
  animation: buttonPress 0.3s forwards; /* Trigger animation on click */
}

@keyframes buttonPress {
  0% {
    transform: scale(1);
    opacity: 1;
    background-color: #0b91d2;
  }
  50% {
    transform: scale(1.25);
    opacity: 0.5;
    background-color: #0a53be; /* Darker on click */
  }
  100% {
    transform: scale(1);
    opacity: 1;
    background-color: #0b91d2;
  }
}
</style>
