<template>
  <div class="container">
    <div class="row justify-content-center">
      <!-- Error Modal -->
      <ErrorModal :sub-title="modalSubtitle" :title="modalTitle" :error-message="modalErrorMessage" />
      <div class="col-md-7"> <!-- Adjust the column size for a smaller card -->
        <div class="upload-card shadow-sm">
          <p v-if="!selectedFormat">
            Effortlessly translate your applications! Simply upload your translation file(s), select the platform and format. We’ll take
            care of the rest.
          </p>
          <p v-if="selectedFormat === '.xcstrings'">
            iOS translation files, known as 'xcstrings,' store all languages in a single file, which is why only one file is required for upload.
          </p>
          <!-- File Drop Area -->
          <!-- Info icon with tooltip -->
          <label for="context" class="form-label">
            Upload file(s)
            <span class="info-icon" @mouseover="showSourceLanguageTooltip = true" @mouseleave="showSourceLanguageTooltip = false">
              <i class="fa-regular fa-circle-question"/>
              <div class="field-tooltip">
                {{translationUploadFileInfo}}
              </div>
            </span>
          </label>
          <div
              class="drop-zone"
              @dragover.prevent
              @drop="handleFileDrop"
              @click="triggerFileInput"
          >
            <p v-if="files.length === 0" class="text-muted">Drag & drop your file here or <span class="text-primary">click to select</span></p>
            <template v-if="files.length > 0">
              <p v-for="(file, index) in files" :key="index" class="font-weight-bold">{{file.name}}</p>
            </template>
            <input
                type="file"
                ref="fileInput"
                @change="handleFileInput"
                :accept="acceptFormats"
                hidden
                multiple
            />
            <p v-if="fileError.length > 0" class="alert alert-danger">{{fileError}}</p>
          </div>
          <!-- Platform Selection -->
          <div class="mt-4" v-if="formatLandingPage === false">
            <label for="platform" class="form-label">Select Platform</label>
            <select class="form-select" v-model="selectedPlatform" id="platform" @change="updateFormats" :disabled="files.length === 0">
              <option disabled value="">Choose a platform</option>
              <option value="iOS">iOS</option>
<!--              <option value="Android">Android</option>-->
<!--              <option value="Java">Java</option>-->
<!--              <option value="Web">Web</option>-->
<!--              <option value="Python">Python</option>-->
<!--              <option value="Ruby on Rails">Ruby on Rails</option>-->
<!--              <option value="C# and .NET">C# and .NET</option>-->
<!--              <option value="Flutter">Flutter</option>-->
<!--              <option value="Game Development">Game Development</option>-->
<!--              <option value="HTML/JavaScript">HTML/JavaScript Frameworks</option>-->
            </select>
          </div>

          <!-- File Format Selection -->
          <div class="mt-4" v-if="formatLandingPage === false">
            <label for="format" class="form-label">Select File Format</label>
            <select
                class="form-select"
                v-model="selectedFormat"
                id="format"
                :disabled="!selectedPlatform"
            >
              <option disabled value="">Choose a format</option>
              <option v-for="format in availableFormats" :key="format" :value="format">{{ format }}</option>
            </select>
          </div>
          <div class="mt-4">
            <label for="languageSelect" class="form-label">Select Language(s)</label>
            <multiselect ref="multiselect" v-model="selectedLanguages" deselect-label="Can't remove this value" track-by="name" label="name" multiple="true"
                         placeholder="Select one" :options="languages" :searchable="true" :allow-empty="false" :clear-on-select="false"
                         :close-on-select="false" :limit="2" @select="onSelect" @remove="onSelect" :show-labels="false">
              <template #option="props">
                <span>{{props.option.name}}</span>
              </template>
            </multiselect>
          </div>
          <!-- Source language -->
          <div class="mt-4">
            <label for="context" class="form-label">
              Source language
              <!-- Info icon with tooltip -->
              <span class="info-icon" @mouseover="showSourceLanguageTooltip = true" @mouseleave="showSourceLanguageTooltip = false">
                <i class="fa-regular fa-circle-question"/>
                <div class="field-tooltip">
                  {{ formatToSourceLanguageInfo }}
                </div>
              </span>
            </label>
            <input
                class="form-control"
                id="source_language"
                v-model="sourceLanguage"
                placeholder="Language used as source"
                maxlength="32"
            />
          </div>

          <!-- User email -->
          <div class="mt-4">
            <label for="format" class="form-label">Email</label>
            <input
                class="form-control"
                id="user_email"
                ref="user_email"
                v-model="userEmail"
                placeholder="Email to send the translated files to"
                required
                maxlength="64"
                type="email"
            />
          </div>

          <!-- User email -->
          <div class="mt-4">
            <label for="format" class="form-label">Currency to pay in</label>
            <select
                class="form-select"
                v-model="selectedCurrency"
                id="currency"
            >
              <option value="USD">USD</option>
              <option value="EUR">EUR</option>
              <option value="JPY">JPY</option>
              <option value="GBP">GBP</option>
              <option value="AUD">AUD</option>
              <option value="CAD">CAD</option>
              <option value="CHF">CHF</option>
              <option value="HKD">HKD</option>
              <option value="NZD">NZD</option>
            </select>
          </div>
          <!-- Confirmation Button -->
          <div class="mt-4 text-center">
            <button
                class="btn btn-primary"
                @click="confirmUpload"
                :disabled="files.length === 0 || !selectedFormat || selectedLanguages.length === 0 || userEmail.length === 0"
            >
              Confirm Upload
            </button>
          </div>
        </div>
      </div>
    </div>
      <!-- Copyright Text -->
  <div class="text-center mt-5">
    <p>&copy; 2024 UAR Services. All Rights Reserved.</p>
  </div>
  </div>

</template>

<script>
import axios from "axios"
import Multiselect from 'vue-multiselect'
import { Modal } from 'bootstrap/dist/js/bootstrap.bundle.min.js';
import ErrorModal from './ErrorModal'

export default {
  components: {Multiselect, ErrorModal},
  data() {
    return {
      formatLandingPage: false,
      modalErrorMessage: "",
      modalSubtitle: "",
      modalTitle: "",
      fileError: "",
      files: [],
      selectedPlatform: "",
      selectedCurrency: "USD",
      selectedFormat: "",
      context: "",
      sourceLanguage: "",
      userEmail: "",
      acceptFormats: [".xcstrings", ".strings"],
      selectedLanguages: [],
      special_code: "",
      translationUploadFileInfo: "When having translation files per language, make sure the file name of each translation file has the correct language code e.g. for .strings files Localizable-ar.strings {filename}-{language code}.{extension}.",
      formats: {
        "iOS": [".xcstrings", ".strings"],
        "Android": ["strings.xml"],
        "Java": [".properties", ".resx"],
        "Web": ["json", ".po"],
        "Python": [".po"],
        "Ruby on Rails": [".yml", ".yaml"],
        "C# and .NET": [".resx"],
        "Flutter": [".arb"],
        "Game Development": [".csv"],
        "HTML/JavaScript": ["i18next"]
      },
      showSourceLanguageTooltip: false,
      availableFormats: [],
      languages: [
        {"name": "Select all", "code": "select-all"},
        {"name": "Arabic", "code": "ar"},
        {"name": "Catalan", "code": "ca"},
        {"name": "Czech", "code": "cs"},
        {"name": "Danish", "code": "da"},
        {"name": "German", "code": "de"},
        {"name": "Greek", "code": "el"},
        {"name": "English", "code": "en"},
        {"name": "English (Australia)", "code": "en-AU"},
        {"name": "English (United Kingdom)", "code": "en-GB"},
        {"name": "English (India)", "code": "en-IN"},
        {"name": "Spanish", "code": "es"},
        {"name": "Spanish (Latin America)", "code": "es-419"},
        {"name": "Finnish", "code": "fi"},
        {"name": "French", "code": "fr"},
        {"name": "French (Canada)", "code": "fr-CA"},
        {"name": "Hindi", "code": "hi"},
        {"name": "Croatian", "code": "hr"},
        {"name": "Hungarian", "code": "hu"},
        {"name": "Indonesian", "code": "id"},
        {"name": "Italian", "code": "it"},
        {"name": "Japanese", "code": "ja"},
        {"name": "Korean", "code": "ko"},
        {"name": "Malay", "code": "ms"},
        {"name": "Norwegian (Bokmål)", "code": "nb"},
        {"name": "Dutch", "code": "nl"},
        {"name": "Polish", "code": "pl"},
        {"name": "Portuguese (Brazil)", "code": "pt-BR"},
        {"name": "Portuguese (Portugal)", "code": "pt-PT"},
        {"name": "Romanian", "code": "ro"},
        {"name": "Russian", "code": "ru"},
        {"name": "Slovak", "code": "sk"},
        {"name": "Swedish", "code": "sv"},
        {"name": "Thai", "code": "th"},
        {"name": "Turkish", "code": "tr"},
        {"name": "Ukrainian", "code": "uk"},
        {"name": "Vietnamese", "code": "vi"},
        {"name": "Chinese (Simplified)", "code": "zh-Hans"},
        {"name": "Chinese (Traditional)", "code": "zh-Hant"},
        {"name": "Chinese (Hong Kong)", "code": "zh-HK"}
      ]
    };
  },
  computed: {
    formatToSourceLanguageInfo() {
      if (this.selectedFormat === ".xcstrings") {
        this.$gtm.trackEvent({
          event: "view-xcstrings-home"
        })
        return "Give the language code which should be used as the source to translate the other languages. This code should be similar as in the .xcstring file."
      }
      return "The language from which the translation will be made."
    },
    acceptedInputFormats() {
      return this.acceptFormats.join(", ")
    }
  },
  mounted() {
    this.setSelectedFormatAndPlatform();
    const params = new URLSearchParams(window.location.search);
    this.special_code = params.get("special_code")
  },
  methods: {
    setSelectedFormatAndPlatform() {
      const params = new URLSearchParams(window.location.search);
      if (params.get('selected_format')?.toLowerCase() === '.xcstrings') {
        this.selectedPlatform = "iOS"
        this.updateFormats(".xcstrings")
        this.formatLandingPage = true;
      }
    },
    showError(title, subTitle, message) {
      this.modalErrorMessage = message;
      this.modalTitle = title;
      this.modalSubtitle = subTitle;
      const modal = new Modal(document.getElementById('errorModal'));
      modal.show();
    },
    updateFormatAndPlatform() {
      let fileType = this.files[0].name.split(".").pop()
      if (fileType === "xcstrings") {
        this.selectedPlatform = "iOS"
        this.updateFormats(".xcstrings")
      }
    },
    onSelect(selectedOption) {
      if (selectedOption.code === "select-all") {
        this.selectedLanguages = this.languages
        this.languages[0].name = "Deselect all"
        this.languages[0].code = "deselect-all"
      } else if (selectedOption.code === "deselect-all") {
        this.selectedLanguages = []
        this.languages[0].name = "Select all"
        this.languages[0].code = "select-all"
      }
      this.selectedLanguages = this.selectedLanguages.filter(v => v.code !== "select-all" && v.code !== "deselect-all")
      this.$refs.multiselect.deactivate();
    },
    handleFileDrop(event) {
      event.preventDefault();
      this.processFileInput(event.dataTransfer.files)
    },
    handleFileInput(event) {
      this.processFileInput(event.target.files)
    },
    processFileInput(files) {
      this.fileError = ""
      if (files.length === 0) {
        return;
      }
      let _files = []
      for (let file of files) {
        if (this.acceptFormats.indexOf(`.${this.getFileType(file)}`) !== -1) {
          _files.push(file)
        }
      }
      if (_files.length === 0) {
        this.fileError = `Invalid file. Currently the following file types ${this.acceptFormats.join(",")} are supported.`
        return;
      }
      let firstFileType = this.getFileType(_files[0])
      for (let file of _files) {
        let fileType = this.getFileType(file)
        if (fileType !== firstFileType) {
          this.fileError = "Mixed file types are not supported."
          return;
        }
      }
      this.files = _files
      this.updateFormatAndPlatform(firstFileType)
    },
    getFileType(file) {
      return file.name.split(".").pop()
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    updateFormats(selectFormat="") {
      this.availableFormats = this.formats[this.selectedPlatform] || [];
      this.selectedFormat = selectFormat;
    },
    checkValidity() {
      const emailInput = this.$refs.user_email;
      if (emailInput.checkValidity()) {
        // Handle the submission with JavaScript if valid
        return true
        // Place your custom JavaScript request handling here
      } else {
        // Show the default HTML5 validation message if invalid
        emailInput.reportValidity();
        return false
      }
    },
    async confirmUpload() {
      if (!this.checkValidity()) {
        return;
      }
      if (this.files.length > 0 && this.selectedFormat) {
        // Proceed with file upload
        await this.purchase();
      } else {
        this.validationError = "Please select a file, platform, and format.";
      }
    },
    async purchase() {
      let files = Array.from(this.files).map(file => {
        let reader = new FileReader()
        return new Promise((resolve, reject) => {
          reader.onload = () => {
            // Get the Base64 string (without the data URL part)
            const base64String = reader.result.split(',')[1];
            resolve(base64String)
          }
          reader.onerror = (error) => {
            console.error('Error reading file', error);
            this.validationError = 'Error reading file';
            reject(this.validationError)
          };
          reader.readAsDataURL(file)
        })
      })

      const data = {
        files: await Promise.all(files),
        platform: this.selectedPlatform,
        format: this.selectedFormat,
        currency: this.selectedCurrency,
        source_language: this.sourceLanguage,
        languages: this.selectedLanguages.map(v => v.code),
        context: this.context,
        user_email: this.userEmail,
        special_code: this.special_code,
      };
      axios.post("https://payment.verifier.arr-services.nl/api/v1/mollie/_purchase", {
        data: data,
        app: "ai_service/mass_translator",
        product_id: "mass_translator.translation",
        is_test: false // only enable during dev
      }).then(res => {
        if (res.status === 200 && res.data.success) {
          window.location = res.data.data.payment_url
        } else {
          this.hasError = true
        }
      }).catch(error => {
        if (error.response) {
          let response = error.response
          if (!response.data.success) {
            if (response.data.data.message === "invalid_format") {
              this.showError("File content is not valid", "The following validation error occurred", response.data.data.data)
            }
          }
        }
      })
    },
  }
};
</script>

<style>
.container {
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.upload-card {
  padding: 40px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  max-width: 500px; /* Increased width */
  margin: auto; /* Centers the card horizontally */
}

img {
  max-height: 320px;
  width: auto;
}

.drop-zone {
  border: 2px dashed #007bff;
  padding: 10px; /* Increased padding for better spacing */
  border-radius: 10px;
  margin-bottom: 20px;
  background-color: #e9ecef;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.drop-zone:hover {
  background-color: #d1e7dd;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  padding: 12px 25px; /* Increased button padding */
  border-radius: 5px;
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
}

body::before {
  content: "";
  background-image: url('../assets/background.jpg'); /* Replace with your background image */
  background-size: cover;
  background-position: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0.8;
}

.info-box {
  color: white;
  font-weight: 500;
  text-align: left;
}

textarea {
  resize: none !important;
}

.info-icon {
  position: relative;
  cursor: pointer;
  font-size: 14px;
  margin-left: 8px;
}

.field-tooltip {
  position: absolute;
  top: 25px;
  left: 0;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  text-align: left;
  z-index: 1000;
  max-width: 250px;
  width: 250px;
  height: auto;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.info-icon:hover .field-tooltip {
  visibility: visible;
  opacity: 1;
}

.col-md-4 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-box {
  padding: 20px;
  max-width: 350px; /* Optional: Adjust width for better fit */
  margin-left: 30px;
}

</style>