<template>
  <template v-if="!isProcessing">
      <TranslatorView/>
  </template>
  <template v-else>
    <PaidView/>
  </template>
</template>

<script>
import TranslatorView from './components/TranslatorView.vue'
import PaidView from './components/PaidView.vue'

export default {
  name: 'App',
  components: {
    TranslatorView,
    PaidView
  },
  computed: {
    isProcessing() {
      const params = new URLSearchParams(window.location.search);
      return params.get('is_processing')?.toLowerCase() === 'true';
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
